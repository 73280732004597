.App {
  text-align: center;
  margin-top: 10%;
}

.App.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - (2 * var(--space)));
}

body {
  margin: 0;
  padding: 0;
  background-color: #673b88; /* 배경색 설정 */
}

.character {
  transition: transform 0.5s ease-in-out;
}

.character.enlarged {
  transform: scale(1.2); /* 원하는 크기로 조정 가능 */
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.joke-box {
  padding: 20px;
  background-color: white;
  border-radius: 10px; /* 상자 모서리 둥글게 */
}

.character {
  width: 200px;
}

.joke-box p {
  font-size: 24px; /* 글씨 크기를 키웁니다 */
  font-weight: bold; /* 글씨를 굵게 만듭니다 */
}

@media (max-width: 600px) {
  .character {
    width: 100px;
  }
}
